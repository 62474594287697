import React,{ useState ,useEffect }  from 'react'
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx"

// import "animate.css/animate.compat.css"
import { NavLink, Link} from 'react-router-dom'

const Header = () => {
  let Links=[
    {name:'HOME ' ,link:'/'},
   
    {name:'BOOKING' ,link:'/hotels'},
    {name:'CULTURAL TOURISM' ,link:'/culturaltourism'},
    {name:'MEDICAL TOURISM' ,link:'/medicaltourism'},
    {name:'CONTACT US' ,link:'/contactus'}
]
const [navbarColor, setNavbarColor] = useState('transparent'); // Initial color when at the top

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            if (scrollPosition > 100) {
                setNavbarColor('rgb(255 255 255 / 0.9)'); // Change to your desired color when scrolled
            } else {
                setNavbarColor('transparent'); // Change back to transparent or initial color when at the top
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
const [isOpen,setIsOpen] = useState(false);
  return (
    <div className='       '>
    <nav style={{ backgroundColor: navbarColor, transition: 'background-color 0.3s ease' }} className=' shadow-md h-22  top-0 left-0 fixed z-40 py-0 w-[100vw]  lg:flex justify-center lg:justify-around items-center'>
    
      <div className='pl-10 lg:pl-10     py-2 flex flex-col text-2xl cursor-pointer  '>
   
 <Link to='/'> <img  className=' h-24 my-0 py-0' src='/assets/logo/kdd343.png'></img>
         <p className='animate-pulse  tagline w-max bg-black/25 text-sm px-1 rounded-lg flex font-bold shadow-lg  capitalize'>
          <span className='  text-green-500'>Travel.</span><span className=' text-orange-400'>Culture.</span><span className='text-blue-500 '>India.</span>
          </p></Link>
       
        
      </div>
     
     
        <div className=''>
        <ul 
       className= {`text-white  lg:visible flex flex-col justify-center lg:justify-end lg:flex-row   lg:flex transition-all   px-0   lg:px-4 text-xl lg:items-center lg:my-2 pb-12 lg:pb-0 lg:right-0  lg:z-auto z-[-1]  w-full   duration-700 ease-out  bg-[#433a8b] h-[100lvh] lg:h-28
        lg:bg-transparent lg:mr-10  lg:top-0  ${isOpen ? 'visible  top-0 left-0 right-0 ': 'invisible absolute lg:relative top-[-755px]'}` }
       >
      
        {
            Links.map(link=>
                        <li onClick={() => setIsOpen(!isOpen)} className='nav-link group font-sans  px-0 py-[2px] rounded hover:text-black lg:text-black  lg:hover:bg-transparent lg:hover:text-[#4338ab]     font-semibold my-6  lg:my-0 lg:px-2 text-center justify-around '>
                            <NavLink className={'flex flex-col'} to={link.link}>{link.name}<div className=' hidden lg:group-active:block lg:group-hover:block rounded-full  h-1.5  bg-orange-400'></div></NavLink>
                        
                        </li>
            )
        }
      </ul>
        </div>
        <div onClick={() => setIsOpen(!isOpen)} className='w-20 absolute top-12 font-bold right-0 cursor-pointer text-black lg:hidden '>
        {
          isOpen ? <div><RxCross1  size={30} /></div> : <div><RxHamburgerMenu size={30}/></div>
        }
        </div>
       
      
    
    </nav>
    </div>
  )
};

export default Header
