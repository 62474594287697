import React,{useState} from 'react'
import './Loader.css'
const PreLoader = () => {
    const[loading,setLoading]=useState(true);
     const spinner= document.getElementById("spinner");
     if(spinner){
      setTimeout(()=>{
        spinner.style.display ='none';
        setLoading(false);
      }, 2000);
     }
    
  return (
    
        <div>
        
        <div id="spinner" class="loadbox">
        <div class="loading text-center flex items-center justify-center"><h1 className='text-xl text-white font-bold'>Loading....</h1></div>
      </div>
      </div>
    
    
  )
}

export default PreLoader
